module.exports={
  "_args": [
    [
      {
        "raw": "webvr-polyfill@^0.9.36",
        "scope": null,
        "escapedName": "webvr-polyfill",
        "name": "webvr-polyfill",
        "rawSpec": "^0.9.36",
        "spec": ">=0.9.36 <0.10.0",
        "type": "range"
      },
      "X:\\Development\\aframe"
    ]
  ],
  "_from": "webvr-polyfill@>=0.9.36 <0.10.0",
  "_id": "webvr-polyfill@0.9.36",
  "_inCache": true,
  "_location": "/webvr-polyfill",
  "_nodeVersion": "4.8.4",
  "_npmOperationalInternal": {
    "host": "s3://npm-registry-packages",
    "tmp": "tmp/webvr-polyfill-0.9.36.tgz_1499892972378_0.10267087002284825"
  },
  "_npmUser": {
    "name": "jsantell",
    "email": "jsantell@gmail.com"
  },
  "_npmVersion": "2.15.11",
  "_phantomChildren": {},
  "_requested": {
    "raw": "webvr-polyfill@^0.9.36",
    "scope": null,
    "escapedName": "webvr-polyfill",
    "name": "webvr-polyfill",
    "rawSpec": "^0.9.36",
    "spec": ">=0.9.36 <0.10.0",
    "type": "range"
  },
  "_requiredBy": [
    "/"
  ],
  "_resolved": "https://registry.npmjs.org/webvr-polyfill/-/webvr-polyfill-0.9.36.tgz",
  "_shasum": "4b1e1556667e804beb0c8c2e67fdfcba3371e8c6",
  "_shrinkwrap": null,
  "_spec": "webvr-polyfill@^0.9.36",
  "_where": "X:\\Development\\aframe",
  "authors": [
    "Boris Smus <boris@smus.com>",
    "Brandon Jones <tojiro@gmail.com>",
    "Jordan Santell <jordan@jsantell.com>"
  ],
  "bugs": {
    "url": "https://github.com/googlevr/webvr-polyfill/issues"
  },
  "dependencies": {},
  "description": "Use WebVR today, on mobile or desktop, without requiring a special browser build.",
  "devDependencies": {
    "chai": "^3.5.0",
    "jsdom": "^9.12.0",
    "mocha": "^3.2.0",
    "semver": "^5.3.0",
    "webpack": "^2.6.1",
    "webpack-dev-server": "^2.4.5"
  },
  "directories": {},
  "dist": {
    "shasum": "4b1e1556667e804beb0c8c2e67fdfcba3371e8c6",
    "tarball": "https://registry.npmjs.org/webvr-polyfill/-/webvr-polyfill-0.9.36.tgz"
  },
  "gitHead": "5f8693a9053ee1dea425e96d14cd1f2bef7a284c",
  "homepage": "https://github.com/googlevr/webvr-polyfill",
  "keywords": [
    "vr",
    "webvr"
  ],
  "license": "Apache-2.0",
  "main": "src/node-entry",
  "maintainers": [
    {
      "name": "jsantell",
      "email": "jsantell@gmail.com"
    },
    {
      "name": "toji",
      "email": "tojiro@gmail.com"
    },
    {
      "name": "smus",
      "email": "boris@smus.com"
    }
  ],
  "name": "webvr-polyfill",
  "optionalDependencies": {},
  "readme": "ERROR: No README data found!",
  "repository": {
    "type": "git",
    "url": "git+https://github.com/googlevr/webvr-polyfill.git"
  },
  "scripts": {
    "build": "webpack",
    "start": "npm run watch",
    "test": "mocha",
    "watch": "webpack-dev-server"
  },
  "version": "0.9.36"
}
